.header {
    position: relative;
    height: 60vh;

    @include media(md) {
        height: 80vh;
    }
}

.header__image {
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    height: 100%;
    width: 100%;

    opacity: 0;
    transition: opacity 1000ms ease-in-out;
}

.header__video {
    cursor: pointer;

    &:before {
        content: '';
        width: 100%;
        // cant add before on video element
        height: 100%;
        pointer-events: none;
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        cursor: default;
    }

    &.header__overlay--hidden {
        &:before {
            display: none;
        }
    }

    video, iframe {
        width: 100%;
    }

    video {
        object-fit: cover;
    }
}

.header__content {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 10%;

    @include media(md) {
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
    }

    &--hidden {
        display: none;
    }
}

.header__title {
    @include BourtonBase(60px);
    color: $color_white;
    text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.35);
    padding-top: 10px;

    @include media(md) {
        font-size: 100px;
    }
}

.header__button {
    color: $color_white;
    border-bottom: 1px solid $color_white;
    @include Roboto(700, normal, $font-size--desktop);
    text-transform: uppercase;
}

.show {
    opacity: 1;
}

.header_campaign_landing {
    height: 60vh;

    @include media(md) {
        height: 80vh;
    }

    .header__content {
        top: unset;
        bottom: 10%;
    }

    .header__title {
        margin: auto;
    }

    .header__subtitle {
        color: $color_white;
        @include Roboto(700, normal, 16px);
        margin: 40px 0 -10px;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);

        @include media(md) {
            font-size: 35px;
        }
    }

    .header__farmer-name {
        color: $color_white;
        @include BourtonBase(60);
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
        margin: 0;

        @include media(md) {
            font-size: 114px;
        }
    }
}
