.map-page__title {
    margin-top: 100px;
}

.map-component {
    background-color: $color-white;
    position: relative;
    min-height: 80vh;
    width: 100%;

    .block__title {
        font-size: 42px;
        padding-bottom: 0;
        text-align: left;
        color: $color_orange;

        &:after {
            content: none;
        }
    }

    // google maps style overrides:

    // the bottom arrow thingy of the infoWindow
    .gm-style {
        &-iw-t,
        &.gm-style-iw-tc {
            ::after {
                display: none !important;
            }
        }

        &-iw-c {
            background-color: transparent !important;
            box-shadow: none !important;
            padding: 0 !important;
            max-width: unset !important;

            > .gm-style-iw-d {
                background-color: transparent !important;
                overflow: hidden !important;

                width: 320px !important;
                height: 320px !important;

                @include media(sm) {
                    width: 340px !important;
                    height: 340px !important;
                }

                > div {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }
    }

    // the close btn
    .gm-ui-hover-effect {
        opacity: 1 !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        right: unset !important;
        top: 15px !important;

        > img {
            width: 34px !important;
            height: 34px !important;
            margin: 0 !important;
            filter: invert(24%) sepia(3%) saturate(6822%) hue-rotate(314deg) brightness(100%) contrast(103%);
        }
    }
}

.map-component__search,
.map-component__legend {
    @include media(lg) {
        width: 40%;
    }

    &-inner {
        position: relative;
        padding: 20px;
        margin: 0;

        @include media(lg) {
            max-width: 470px;
            margin-left: auto;
            margin-right: 0;
        }
    }
}

.map-component__search {
    @include media(lg) {
        padding: 45px 30px;
    }
}

.map-component__input {
    @include BourtonBase(18);
    border: 1px solid $color_creme;
    padding: 23px 22px 21px 22px;
    width: 100%;

    &::placeholder {
        @include BourtonBase(18);
        text-transform: uppercase;
    }
}

.map-component__button {
    background: white;
    position: absolute;
    top: 50%;
    right: 40px;
    width: 28px;
    height: 28px;
    transform: translateY(-50%);
    overflow: visible;

    &:before {
        display: block;
        content: '';
        position: absolute;
        left: -150%;
        top: 0;
        height: 100%;
        width: 150%;
        background: transparent;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 85%);
    }

    &:after {
        background-image: url('/static/images/search.svg');
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &.has-value:after {
        background-image: url('/static/images/close.svg');
    }
}

.map-component__map {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 107px);
    position: relative;
    background-color: $color_creme;

    @include media(md) {
        min-height: 640px;
    }

    @include media(lg) {
        width: 60%;
        position: absolute !important;
        top: 0;
        right: 0;
        min-height: calc(100vh - 331px);
    }

    .underlay {
        transition: background-color 125ms ease;
        background-color: rgba(0, 0, 0, 0);
        display: block;
        position: absolute;
        pointer-events: none;

        &.visible {
            background-color: rgba(208, 149, 86, 0.5);
        }
    }

    .location-cluster {
        position: relative;

        > div {
            position: absolute;
            display: block;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 2px solid $color_orange;
            background-color: $color_white;
            @include Roboto(700, normal, 16);
            line-height: 42px !important;
            color: $color_orange !important;
        }

        > img {
            display: none !important;
            visibility: hidden;
        }
    }
}

.map-info-panel {
    text-align: center !important;
    pointer-events: none;
    user-select: none;

    padding: 60px 40px 80px 40px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        max-width: 180px !important;
        max-height: 90px;
        height: auto;
    }

    .icon {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        height: 50px;

        &:before {

            display: inline-block;
            content: '';
            width: 50px;
            height: 50px;
            background-position: center bottom;
            background-repeat: no-repeat;
            vertical-align: middle;
        }

        &__farm:before {
            background-image: url("/static/images/marker-farm.svg");
        }

        &__location:before {
            background-image: url("/static/images/marker-location.svg");
        }
    }
}

.map-info-panel__label {
    @include LumiosMarker(36px);
    color: $color_orange;
}

.map-info-panel__title {
    @include BourtonBase(24px);
    color: $color_black;
    margin: 0;
}

.map-info-panel__address {
    span {
        @include Roboto(400, normal, 16px);
        display: block;
        color: $color_black;
        line-height: 26px;
    }
}

.map-component__legend {
    @include media(lg) {
        padding: 0 30px 45px 30px;
    }

    ul > li {
        padding: 10px 0;
    }
}

.map-component__legend-item {
    position: relative;
    margin-bottom: 30px;

    span {
        @include BourtonBase(18);
        text-transform: uppercase;
        color: $color_black;
        margin-left: 70px;
    }

    &:before {
        position: absolute;
        content: '';
        width: 50px;
        height: 50px;
        left: 0;
        top: 50%;
        background-position: center center;
        background-repeat: no-repeat;
        margin-right: 20px;
        vertical-align: middle;
        transform: translateY(-50%);
    }

    &--farm:before {
        background-image: url("/static/images/marker-farm.svg");
    }

    &--location:before {
        background-image: url("/static/images/marker-location.svg");
    }
}
