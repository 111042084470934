.cow__image {
    text-align: center;
    height: 200px;

    @include media(md) {
        height: 400px;
    }
}

.cow__name {
    color: $color_orange;
    @include LumiosMarker;
    font-size: 50px;
}

.cow__text {
    line-height: 25px;
    padding-bottom: 30px;
    @include Roboto(700, normal, $font-size--desktop);
}

.cow__link {
    margin-left: 5px;
    padding: 0 4px 5px;
    color: $color_orange;
    border-bottom: 1px solid $color_orange;
    @include Roboto(700, normal, $font-size--mobile);
}

.cow__filter {
    display: flex;
    justify-content: space-evenly;

    margin-bottom: 60px;
}

.cow__filter-button {
    color: $color_blue;
    position: relative;
    text-transform: capitalize;
    z-index: 1;
    @include BourtonBase($font-size--mobile);

    @include media(md) {
        font-size: $h4-font-size--desktop;
    }

    &--active {
        color: $color_white;

        &:after {
            content: '';
            position: absolute;
            height: 25px;
            width: 100%;
            padding: 0 10px;
            top: -6px;
            left: -10px;
            transform: rotate(-5deg);
            z-index: -1;
            background-color: $color_blue;
            box-sizing: unset;

            @include media(md) {
                height: 40px;
                left: -20px;
                width: 100%;
                padding: 0 20px;
                top:-9px;
            }
        }
    }
}

.cow {
    display: none;
    padding-bottom: 30px;


    &__show {
        display: flex;
        min-height: 600px;
        flex-direction: column;

        @include media(md) {
            flex-direction: row;
        }

        .cow__wrapper {
            @include media(md) {
                min-height: 400px;
                width: 50%;
            }
        }
    }
}
