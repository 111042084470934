.production-process {
    background: white;
    margin: 42px -20px;

    @include media(md) {
        //max-width: 960px;
        margin: 42px -42px;
    }

    &__sticky-container {
        position: sticky;
        z-index: 10;
        top: 30px;
        margin-bottom: -382px;
        height: 400px;

        @include media(md) {
            position: absolute;
            top: -16px;
        }
    }

    @include media(lg) {
        //max-width: 1180px;
        margin: 42px -50px;
    }

    &__item {
        position: relative;
        display: inline-block;
        width: 100%;
        margin-top: -18px;
    }

    &__image-container,
    &__content-container {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    &__content-container {
        padding-bottom:20px;
    }

    &__content-container--bg-beige {
        background-color: #ECE8E1;
    }

    &__content-container p,
        //&__content-container h2,
    &__content-container h3 {
        transition: all 0.5s;
        transform: translateY(-150px);
        opacity: 1;

        @include media(md) {
            transform: translateY(0);
        }
    }

    &--sticky-title-dark &__content-container p,
        //&--sticky-title-dark &__content-container h2,
    &--sticky-title-dark &__content-container h3 {
        transform: translateY(0);
        opacity: 1;
    }

    &__image-container {
        height: 600px;
        //margin-top: 20px;
        /* margin-bottom: -20px; */
        width: 100%;
        /* position: absolute; */
    }

    @include media(md) {

        &__item {
            display: flex;
            min-height: 850px;
            margin-top: 0;
        }

        &__image-container,
        &__content-container {
            flex-direction: column;
            flex: 1;
            min-height: 100%;
            height: unset;
            width: 50%;
        }

        &__sticky-container {
            flex: 0 0 100%;
            position: absolute;
            width: 50%;
        }
    }

    &__day-label-container {
        text-align: center;
    }

    &__image-day-label-container {
        @include media(md) {
            visibility: hidden;
        }
    }

    &--sticky-day-label-container-hidden &__image-day-label-container {
        visibility: hidden;

        @include media(md) {
            visibility: visible;
        }
    }

    &__content-day-label-container {
        visibility: hidden;
        position: absolute;
        width: 100%;
        margin-top: -20px;

        @include media(md) {
            position: static;
            visibility: visible;
            margin: 118px 0 50px;
        }
    }

    &--sticky-day-label-container-hidden &__content-day-label-container {
        visibility: visible;
    }


    &__day-label {
        @include BourtonBase(32px);
        display: inline-block;
        color: #F26752;
        margin-right: 20px;
        vertical-align: -4px;
    }

    &__day-label-number {
        background-image: url(/static/images/production_process_day-label-bg.png);
        background-repeat: no-repeat;
        background-size: 100%;
        @include BourtonBase(20px);
        line-height: 40px;
        color: $color_white;
        display: inline-block;
        width: 34px;
        height: 37px;
        margin-left: -6px;
    }

    &__day-label-number-divider {
        position: absolute;
        margin-left: -16px;
    }

    &__image {
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &__title {
        @include BourtonBase(32px);
        line-height: 32px;
        width: 70%;
        margin-left: 15%;
        position: relative;
        margin-top: 100px;

        @include media(md) {
            font-size: 42px;
            line-height: 42px;
            width: 60%;
            margin-left: 20%;
        }
    }

    &__image-title {
        color: $color_white;
        text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.35);
        transition: color 0.5s, text-shadow 0.5s;
    }

    &--sticky-title-dark &__image-title {
        color: $color_black;
        text-shadow: none;

        @include media(md) {
            color: $color_white;
            text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.35);
        }

    }

    &--sticky-title-hidden &__image-title {
        visibility: hidden;

        @include media(md) {
            visibility: visible;
        }
    }

    &__content-title {
        visibility: hidden;

        @include media(md) {
            display: none;
        }
    }

    &--sticky-title-hidden &__content-title {
        visibility: visible;
        color: $color_black;
    }

    &__icon-container {
        width: 90px;
        height: 98px;
        position: relative;
        background-image: url("/static/images/production_process_icon_bg_white.png");
        background-size: cover;
        margin: auto;
        margin-bottom: 30px;

        @include media(md) {
            width: 180px;
            height: 197px;
        }
    }

    &__content-icon-container {
        @include media(md) {
            display: none;
        }
    }

    &__icon-container--bg-beige {
        background-image: url("/static/images/production_process_icon_bg_brown.png");
    }

    &__image-icon-container {
        transition: all 0.5s;
    }

    &--sticky-title-dark &__image-icon-container {
        opacity: 0;

        @include media(md) {
            opacity: 1;
        }
    }

    &__icon {
        width: 100%;
        height: 100%;
        position:absolute;
        top: 0;
    }

    &__image-icon-container &__icon {
        &--filled {
            clip-path: inset(100% 0 0 0);
        }
    }

    &__subtitle {
        font-size: 16px;
        font-weight: bold;
        margin: 0 15px 60px;
        line-height: 30px;

        @include media(md) {
            font-size: 18px;
            line-height: 36px;
            margin: 0 20% 60px;
        }
    }

    &__text {
        margin: 0 15px 25px;
        line-height: 25px;
        text-align: center;

        @include media(md) {
            line-height: 30px;
            margin: 0 10% 35px;
        }
    }

    &__intro {
        font-weight: bold;
    }


    &__link-ah {
        color:#01A0E2;
        text-decoration: underline;
    }

    &__cta {
        @include BourtonBase(16px);
        display: inline-block;
        background: #F26752;
        color: $color_white;
        border-radius: 8px;
        padding: 8px 20px 5px;
        max-width: 280px;
        line-height: 22px;
    }

    &__cta:hover {
        text-decoration: underline;
    }


}
