button, input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

input {
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
}

input[type=checkbox] {
    -webkit-appearance: checkbox;
}

a {
    text-decoration: none;
    padding: 0;
    color: inherit;
}

ul {
    list-style-type: none;
    padding-left: 0;
}


blockquote {
    margin: 0;
}

textarea {
    border: none;
    overflow: auto;
    box-shadow: none;
}

figure {
    margin: 0;
}
