@mixin ie-n-edge {
    .navigation__collapsible {
        .navigation__socials {
            display: block;
            width: 100%;
            text-align: center;

            a {
                padding: 0 10px;
            }
        }
    }
    .hexagon {
        background-image: url("/static/images/hexagon.png");
    }

    @include media(md) {
        .navigation__items {
            width: 100% !important;
        }
        .navigation__item {
            min-width: 150px;
        }

        .reviewlist {
            &:before {
                display: none;
            }
        }
        .homepage {
            .main {
                &:before {
                    display: none;
                }
            }
        }
    }

    .cow__filter {
        display: block !important;
        width: 100%;
        text-align: center;

        margin-bottom: 60px;

        &-button {
            padding: 0 10px;

            @include media(md) {
                padding: 0 30px;
            }

            &--active {
                background-color: $color_blue;
                padding: 10px 10px;

                &:after {
                    display: none;
                }
            }
        }
    }
}

@supports (display:-ms-grid) {
    @include ie-n-edge;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active), (-ms-ime-align: auto) {
    @include ie-n-edge;
}
