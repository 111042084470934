.cookie {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: center;
    background: $color_creme;
    z-index: 1;

    &--hidden {
        display: none;
    }
}

.cookie__button {
    @include Roboto(700, normal, $font-size--desktop);
    color: $color_brown;
}

.cookie__button--accept {
    margin-right: 10px;
}
