.label-introduction {
    text-align: center;
}

.label-introduction__title {
    margin: 0;
    padding: 0;
    @include BourtonBase(32px);
    text-transform: uppercase;
    line-height: 39px;

    @include media(sm) {
        @include BourtonBase(42px);
        line-height: 55px;
    }
}

.label-introduction__intro,
.label-introduction__text {
    line-height: 32px;
    margin: 0 auto;

    max-width: 780px;
}

.label-introduction__intro {
    @include Roboto(700, normal, $font-size--desktop);
    margin-top: 40px;
}

.label-introduction__text {
    @include Roboto(300, normal, $font-size--desktop);
    margin-top: 20px;
}
