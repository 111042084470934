.navigation {
    background-image: url("/static/images/parchment-paper-texture.jpg");
    background-size: cover;
    background-color: $color_creme;
    overflow-y: visible;
    position: relative;
    z-index: 10;

    // prevent scroll when navigation in navigation is shown
    &--collapsed {
        overflow-y: hidden;

        .navigation__close {
            display: block;
        }
    }

    .logo {
        position: absolute;
        top: 10px;
        left: 35px;
        width: 70px;
        height: auto;

        @include media(lg) {
            left: 70px;
        }
    }
}

.navigation__collapsed {
    height: 70px;

    .navigation__items {
        display: none;

        @include media(md) {
            display: flex;
            width: 70vw;
            margin: 0;
            margin-left: 120px;
        }

        @include media(lg) {
            margin-left: 180px;
        }
    }
}

.navigation__collapsible {
    height: 100vh;
    overflow: hidden;
    padding-top: 0;
    opacity: 1;
    transition: transform 1s ease-in;

    @include media(sm) {
        padding-top: 70px;
    }

    &.navigation__collapsible--collapsed {
        opacity: 0;
        display: none;
    }

    .navigation__items {
        @include media(md) {
            display: none;
        }
    }
}

.navigation__items {
    list-style-type: none;
    padding-left: 0;
    text-align: center;
}

.navigation__item {
    position: relative;
    padding: 10px 16px;

    @include media(sm) {
        padding: 25px 16px;
    }

    &--has-children {
        cursor: pointer;
    }
}

.navigation__link {
    @include BourtonBase(28px);
    color: $color_black;
    text-decoration: none;
    line-height: 1;
    display: block;

    @include media(md) {
        font-size: 18px;
    }

    @include media(lg) {
        font-size: 21px;
    }
}

.navigation__socials {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 40vh;
    margin: 0 auto;

    &--collapsed {
        justify-content: space-between;
        float: right;
        width: 90px;
        padding: 23px 20px 0 0;

        @include media(md) {
            width: 130px;
            padding: 22px 20px 0 0;
            position: absolute;
            right: 0;
        }

        img {
            height: 17px;
            @include media(md) {
                height: 23px;
            }
        }

        .navigation--collapsed & {
            display: none;
        }
    }
}

.navigation__toggler {
    @include BourtonBase(16px);
    color: $color_black;
    padding-left: 135px;
    padding-top: 30px;

    @include media(md) {
        display: none;
    }
}

.navigation__close {
    display: none;
    position: absolute;
    right: 20px;
    top: -15px;
    width: 32px;
    height: 60px;
    padding: 0;

    &:after, &:before {
        position: absolute;
        left: 15px;
        content: '';
        height: 33px;
        width: 2px;
        background-color: $color_brown;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

.navigation__sub-items {
    display: block;
    margin-top: 16px;

    @include media(md) {
        display: none;
        position: absolute;
        margin-top: 0;
        left: -16px;
        right: -16px;
        bottom: 1px;
        z-index: 0;
        transform: translateY(100%);
        background-image: url("/static/images/parchment-paper-texture.jpg");
        background-size: cover;
        background-color: $color_creme;
        padding: 0 32px 24px;
        text-align: left;

        .navigation__item--has-children:hover & {
            display: block;
        }
    }
}

.navigation__sub-item {
    padding: 5px 0;

    @include media(md) {
        padding: 10px 0;
    }
}

.navigation__sub-link {
    @include Roboto(400, normal, 16px);
    line-height: 1.25;
    color: $color_black;
}
