.list__item {
    @include Roboto(700, normal, $font-size--mobile);
    padding: 5px 0;

    &:before {
        content: "\2022";
        color: $color_orange;
        float: left;
        margin: 0 0 0 -1em;
        width: 1em;
    }
}
