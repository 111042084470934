.story-grid {

    &__title-wrapper {
        padding: 56px 0;
    }

    &__title {
        text-align: center;
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
    }

    &__tag {
        position: absolute;
        top: 16px;
        left: 16px;
        border-radius: 18px;
        color: white;
        padding: 9px 24px 5px 24px;
        background-color: $color_orange;
        @include BourtonBase(21px);
        z-index: 3;
    }

    &__item {

        position: relative;
        display: block;

        width: calc(100% - 15px);

        @include media(md) {
            width: calc(50% - 15px);
        }

        @include media(lg) {
            width: calc(33% - 15px);
        }

        margin-bottom: 56px;
        &__top {
            position: relative;
            overflow: hidden;

            > img {
                position: relative;
                width: 100%;
                height: auto !important;
                max-height: unset;
                transform: none !important;
                overflow: hidden;
            }
        }

        &__bottom {
            position: relative;
            top: 0;
            width: 100%;
            height: auto !important;

            &__title {
                @include BourtonBase(32px);
                color: $color_orange;
                text-align: left;
                margin-top: 12px;
                margin-bottom: 0;
            }

             &__introduction {
                @include Roboto(400, normal, 16px);
                text-align: left;
                line-height: 25px;
                margin-top: 8px;
                margin-bottom: 0;
            }
        }
    }

    &__controls {

        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;

        .button-next, .button-prev {
            display: inline-block;
            margin: 20px;
        }
    }


    &__pagination {

        display: inline-block;
        text-align: center;

        padding: 0;
        margin: 40px 0;

        &-text {
            @include BourtonBase(22px);
            text-align: center;
            margin-bottom: 40px;

            @include media(md) {
                margin-bottom: 140px;
            }

        }

        >.page-item {
            position: relative;
            display: inline-block;
            overflow: hidden;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background-color: $color-creme;
            margin: 0 4px;
            opacity: 1;

            a {

                display: block;
                width: 100%;
                height: 100%;
                text-indent: -200%;

                &:hover, &:active, &visited {
                  background-color: $color-orange;
                }
            }

            &.active {
                background-color: $color-orange;
            }
        }
    }
}
