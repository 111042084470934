.introduction {
    @include Roboto(700, normal, $font-size--desktop);
    text-align: center;

    @include media(md) {
        margin: 0 auto;
    }

    .button {
        margin-top: 16px;
    }
}
