.usp__title {
    @include BourtonBase(26px);
    color: $color_black;
    margin: 10px 0;

    @include media(md) {
        margin: 22px 0;
    }
}

.usp__text {
    line-height: 25px;
}

.usp__list {
    display: flex;
    flex-direction: column;

    .usp__item {
        padding: 15px 0;

        .hexagon {
            text-align: center;

            span {
                position: relative;
                top: 5px;
            }
        }
    }

    @include media(md) {
        flex-direction: row;
        justify-content: center;

        .usp__item {
            flex: 1 1 0;
            padding: 0 20px;
        }
    }
}
