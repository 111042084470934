.text-block__title {
    color: $color_brown;
}

.text-block {
    .grid__content {
        text-align: center;
    }
    .rich-text > * {
        text-align: center;
    }

    .button {
        margin-bottom: 32px;
    }

    &__date {
        margin-bottom: 32px;
    }
}
