.intro-milk-makers {

    &__list {
        display: flex;
        max-width: 320px;
        margin: -90px auto 50px;

        @include media(md) {
            max-width: 560px;
            margin: -105px auto 90px;
        }
    }

    &__item {
        flex-direction: column;
        flex: 1;
        text-align: center;
    }

    &__link,
    &__content-container {
        display: block;
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        position: relative;
    }

    &__link:hover,
    &__content-container {
        transform: scale(1.2);
    }

    &__list:hover &__content-container:not(:hover) {
        transform: scale(1);
    }

    &__bottle {
        display: block;
        width: 50%;
        margin: auto;
    }

    &__title {
        @include BourtonBase(12px);
        color: #F26752;
        text-align: center;
        position: absolute;
        line-height: 16px;
        width: 100%;
        top: calc(100% - 12px);
        padding: 10px 5px;
        opacity: 0.8;
        transition: all 0.3s;

        @include media(md) {
            font-size: 18px;
            line-height: 20px;
            padding: 10px 10px;
            top: calc(100% - 18px);
        }
    }

    &__link:hover &__title,
    &__list:not(:hover) &__content-container &__title,
    &__list:hover &__content-container:hover &__title {
        opacity: 1;
    }
}
