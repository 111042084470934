.error-page {
    .main {
        padding-top: 50px;
        text-align: center;
    }

    header {
        visibility: hidden;
        display: none;
        height: 0;
    }
}
