.columnar-text {

    --column-count: 2;

    padding: 50px 0;

    .grid__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        font-size: 62px;
    }

    &__text {

        column-count: 1;

        width: calc(33% * var(--column-count));

        @include media(md) {
            column-count: var(--column-count);
        }

        @include richtext();
    }


}
