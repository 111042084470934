.product-carousel {
    position: relative;
    padding-bottom: 0;

    // swiper content
    .wrapper {
        position: relative;
        width: 90%;
        left: 5%;
        height: 420px;

        @include media(md) {
            height: 440px;
        }

        @include media(lg) {
            height: 460px;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        background: none;
        top: 45%;
        width: auto;
        height: auto;

        &:focus {
            outline: 0;
        }

        z-index: 1;
    }

    .swiper-button-next {
        right: -5%;
    }

    .swiper-button-prev {
        left: -5%;
    }
}

.product-carousel__list {
    height: 100%;
    overflow: hidden;

    .swiper-wrapper {
        text-align: center;

        & > * {
            white-space: nowrap;
        }
    }
}


.product-carousel__description {
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 10;
    top: -100px;

    // animation
    > * {
        opacity: 1;
        transition: opacity 250ms ease-in-out;
    }

    &.hidden > * {
        opacity: 0;
    }

    .slide__info {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        vertical-align: middle;

        .slide__title {
            @include Roboto(400, normal, 24px);
            margin: 0;

            //@include media(md) {
            //    font-size: 72px;
            //}
        }

        .slide__description p {
            @include Roboto(400, normal, $font-size--mobile);
            margin: 0;

            @include media(md) {
                font-size: 16px;
            }
        }
    }
}
