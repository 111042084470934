.image-collection__grid {
    display: grid;
    height: 300px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
          "a a b"
          "a a c";

    @include media(md) {
        height: 600px;
    }

    img {
        display: block;
        object-fit: cover;
        overflow: hidden;
        width: 100%;
        height: 100%;

        &:nth-child(1) {
            grid-area: a;
        }

        &:nth-child(2) {
            grid-area: b;
        }

        &:nth-child(3) {
            grid-area: c;
        }
    }
}
