.block__title {
    @include LumiosMarker(42px);
    position: relative;
    color: $color_orange;
    margin-top: 0;

    @include media(sm) {
        font-size: 62px;
    }
}

.block__title--small {
    @include LumiosMarker(32px);
    position: relative;
    color: $color_orange;
    margin-top: 0;

    @include media(sm) {
        font-size: 52px;
    }
}


.block__date {
    @include BourtonBase(22px);
    display: inline-block;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 0;
}


.block__subtitle {
    @include BourtonBase(52px);

    @include media(sm) {
        font-size: 82px;
    }
}
