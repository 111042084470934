@mixin media($point) {
    @if $point == mobile-only {
        @media (max-width: 479px) {
            @content;
        }
    }

    @else if $point == sm {
        @media (min-width: 480px) {
            @content;
        }
    }

    @else if $point == sm-only {
        @media (min-width: 768px) and (max-width: 767px) {
            @content;
        }
    }

    @else if $point == md {
        @media (min-width: 768px) {
            @content;
        }
    }

    @else if $point == lg {
        @media (min-width: 1024px) {
            @content;
        }
    }

    @else if $point == xl {
        @media (min-width: 1280px) {
            @content;
        }
    }
}
