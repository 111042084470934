.instagram-item {

    display: inline;
    float: left;

    height: auto;
    width: 50%;

    @include media(md) {
        width: 33%;
    }

    img {

        box-sizing: border-box;
        padding: 8px;

        width: 100%;
        height: auto;
    }
}


