$parallaxHeight: 60px;

.parallax {
    transform: none;

    //&--effect {
    //    opacity: 0.5;
    //}
    //
    //&--show {
    //    transform: translateY(-$parallaxHeight);
    //    opacity: 1;
    //    transition: all 0.8s ease-in-out;
    //
    //}
}
