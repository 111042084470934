@import url('https://fonts.googleapis.com/css?family=Arvo:400,400i,700,700i|Roboto');

@font-face {
    font-family: 'Bourton Base';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("fonts/BourtonBase.otf") format('opentype'),
         url('fonts/BourtonBase.ttf') format('truetype');
}

@font-face {
    font-family: 'Lumios Marker';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("fonts/Lumios-Marker.otf") format('opentype');
}


@mixin Roboto($weight, $style, $size) {
    font-weight: $weight;
    font-family: 'Roboto', sans-serif;
    font-style: $style;
    font-size: $size;
}

@mixin BourtonBase($size) {
    font-family: 'Bourton Base', serif;
    font-size: $size;
}

@mixin LumiosMarker($size: 18px) {
    font-family: 'Lumios Marker', cursive;
    font-size: $size;
    line-height: 1.05;
}
