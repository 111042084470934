.form__title {
    text-align: left;
    @include Roboto(700, normal, $h4-font-size--mobile);
}

.form__wrapper {
    @include media(md) {
        width: 600px;
        margin: 0 auto;
    }
}

.form__field {
    label {
        @include BourtonBase(18px);
        text-transform: uppercase;
        display: block;
        color: $color_black;
        padding: 10px 0;
    }
    textarea, input {
        width: 100%;
        height: 40px;
        padding: 10px;
        box-sizing: border-box;
        font-size: 16px;
        background-color: $color_creme;
    }

    // email and privacy statement consent
    // weird that a label is required for a hidden field in wagtail forms
    &:last-of-type {
        label {
            display: none;
        }
    }
}

.form__submit {
    @include Roboto(700, normal, $font-size--desktop);
    background-color: $color_orange;
    padding: 10px 20px;
    color: $color_white;
    display: block;
    margin: 30px 0;
    border-radius: 5px;
}

.form__consent {
    position: relative;
    margin-top: 25px;

    label {
        @include BourtonBase(16px);
        padding-left: 30px;
        display: block;
    }
    .form__checkbox {
        float: left;
        top: 0;
        position: absolute;
    }
}

.form__privacy-statement {
    color: $color_orange;
}

// show & hide form sections.
.form__default, .form__success, .form__warning {

    visibility: hidden;
    display: none;

    &.visible {
        visibility: visible;
        display: block;
    }
}

.form__recaptcha p {

    font-size: 12px;
    line-height: 18px !important;
    opacity: 0.75;

    a {
        color: #f26752;
    }
}

.form__warning {
    color: #f26752;
}
