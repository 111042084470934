.social-media {
    color: $color_orange;
    overflow: auto;
}
.social-media__icon {
    float: left;
}
.social-media__text {
    float: left;
    padding: 0 0 0 20px;
    max-width: 200px;
    margin-top: 10px;
    @include BourtonBase($h4-font-size--mobile);

    @include media(sm) {
        max-width: 100%;
        margin-top: 1em;
    }
    @include media(md) {
        font-size: $font-size--mobile;
    }

    @include media(lg) {
        font-size: $h4-font-size--mobile;
    }
}
