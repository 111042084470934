@mixin hexagon($height) {
    position: relative;
    margin: 0 auto;
    width: $height * 0.66;
    height: $height;
    border-radius: 3px;
    background: $color_orange;
    transition: opacity .5s;
    transform: rotate(90deg);
    text-align: left;

    &:before, &:after {
        position: absolute;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        background: inherit;
        content: '';
    }

    &:before {
        transform: rotate(60deg);
    }

    &:after {
        transform: rotate(-60deg);
    }
}

@mixin clearfix() {
    &:after {
        content: "";
        clear: both;
        display: table;
    }
}


@mixin richtext() {
    > * {
        margin-top: 0;
    }

    p {}

    > ol, ul {
        list-style: initial;

        margin-left: 0;
        margin-right: 0;
        padding-left: 16px;
        padding-right: 0;

        line-height: 25px;
    }
}
