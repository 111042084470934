.button {
    @include BourtonBase(16px);
    display: inline-block;
    background-color: $color_orange;
    color: $color_white;
    text-align: center;
    padding: 16px 64px;
    border-radius: 40px;
    line-height: 1;

    &:hover {
        color: $color_orange;
        border: solid 1px $color-orange;
        padding: 15px 63px;
        background-color: transparent;
    }
}

