.embed {
    background-image: url("/static/images/MijnMelk_Pattern_2-06.png");
    background-size: cover;
    background-position: bottom;

    .cow-page__content & {
        background-image: none;
    }

    .block__title {
        color: $color_white;
    }
}

.embed--text-only {
    @include media(md) {
        .embed__content .embed__wrapper {
            width: 70%;
        }
    }
}

.embed__content {
    display: flex;
    flex-direction: column;

    @include media(md) {
        .cow-page__blocks & {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

.embed__wrapper {

    &:first-of-type {
        padding-bottom: 45px;

        @include media(md) {
            padding-bottom: 0;
        }
    }

    &:last-of-type {
        .embed__title--description {
            margin-bottom: 10px;
        }

        .social-media {
            display: none;
        }

        @include media(md) {
            width: 80%;
            margin: 40px auto 0;
        }
    }

    .cow-page__blocks & {
        .social-media {
            display: block;
        }

        .embed__title--description {
            margin-bottom: 30px;
        }

        @include media(md) {
            width: 45%;
            margin: 1em;
        }
    }
}

.embed__video {
    // make youtube embed responsive
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    div {
        padding-bottom: 0 !important;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        margin: 0 auto;

        .ie & {
            display: none;
            visibility: hidden;
        }
    }


}

.embed__error {
    display: none;
    visibility: hidden;

    .ie & {
        display: block;
        visibility: visible;
    }
}

.embed__error-title {
    font-weight: bold;
}

.embed__error-inner {
    background: #ece8e2;
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 24% 20% 0;
    text-align: center;
    line-height: 170%;

    a {
        text-decoration: underline;
    }
}

.embed__data {
    background-color: $color_white;
    padding: 20px 20px;

    .embed--cow-detail & {
        background-color: $color_creme;
    }
}

.embed__title--description {
    @include LumiosMarker;
    text-align: left;
    font-size: 30px;
    color: $color_brown;
    margin-top: 0;
}

.embed__hexagon {
    @include hexagon(20px);
    top: 4px;
    margin: 0;
    background-color: $color_brown;
}

.embed__last-milked {
    @include BourtonBase(26px);
    color: $color_brown;
    margin: 0;
    padding-left: 20px;
}

.embed__last-milked--cow {
    padding-left: 33px;
    flex-basis: 100%;
}
