.cow-page {
    background-image: url("/static/images/MijnMelk_Pattern_2-08.png");
    background-position: 20% 100%;
    color: $color_brown;

    .block__subtitle {
        @include LumiosMarker;
    }
}

.cow-page__content-subtitle {
    @include LumiosMarker;
    color: $color_brown;
    font-size: 30px;
}

.cow-page__content {
    display: flex;
    flex-direction: column;

    @include media(md) {
        flex-direction: row;
        justify-content: space-between;

    }
}

.cow-page__wrapper {
    &:first-of-type {
        width: 100%;
        @include media(md) {
            //flex: 1;
            width: 45%;
        }
        @include media(lg) {
            width: 50%;
        }
    }
}

.cow-page__image {

    img {
        width: 100%;
    }

    @include media(md) {
        padding-left: 20px;
        //flex: 1;
        width: 55%;
    }
    @include media(lg) {
        width: 45%;
    }

    .image {
        @include media(md) {
            height: 50%;
        }
        @include media(lg) {
            height: 80%
        }
    }
}

.cow-page__subtitle {
    @include LumiosMarker;
    font-size: $h4-font-size--mobile;
    color: $color_brown;
    position: relative;
    padding-top: 75px;

    &:after {
        content: '';
        border-left: 1px solid $color_brown;
        position: absolute;
        top: 10px;
        left: 50%;
        height: 40px;
    }

    @include media(md) {
        font-size: $h3-font-size--desktop;
    }
}

.cow-page__information {
    margin: 0;
}

.cow-page__description {
    color: $color_black;
}

.cow-page__blocks {
    margin: 0 auto;

    @include media(md) {
        padding-bottom: 100px;
    }

    .embed {
        background-color: $color_white;

        iframe {
            width: 100%;
            height: 100%;
        }

        .block__title {
            color: $color_orange;
        }

        .block__subtitle {
            @include LumiosMarker;
        }
    }

}

.cow-page__families {
    background-color: $color_orange;
    padding: 25px 0;

    .cow__filter-button {
        color: $color_white;
    }
}

.cow-page__span {
    @include Roboto(700, normal, $font-size--mobile);
}
