.faq__hexagon {
    //@include hexagon(20px);
    pointer-events: none;
    margin: 0 10px 0 0;
    min-width: 30px;
    position: relative;

    .hexagon__text {
        top: 6px;
        width: 33px;
        font-size: 20px;
    }
}

.faq__expand-icon {
    position: absolute;
    z-index: 1;
    font-size: 20px;
    color: $color_white;
    transform: rotate(-90deg);
    transition: transform 0.2s ease;
    //width: 100%;
    text-align: center;

    .faq__question--active & {
        transform: rotate(-45deg);
    }
}

.faqlist {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 100vw;
        left: 0;
        bottom: 0;
        background-color: $color_white;

        @include media(lg) {
            left: 20%;
        }
    }
}

.faq-list__wrapper {
    position: relative;

    @include media(md) {
        width: 600px;
        margin: 0 auto;
    }
}

.faq-list__items {
    padding-bottom: 20px;
}

.faqlist__link {
    margin-left: 39px;
    padding: 0 4px 5px;
    color: $color_orange;
    border-bottom: 1px solid $color_orange;
    font-weight: bold;
}

.faq__question {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}

.faq__question-button {
    display: inline-block;
    padding: 0 37px;
    text-align: left;
    margin: 0px -20px;
    @include Roboto(700, normal, $font-size--desktop);
}

.faq__answer {
    display: none;
}

.faq__question--active + .faq__answer {
    display: block;
    margin-top: -10px;
    margin-left: 59px;
}
