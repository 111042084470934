.image {
    position: relative;
    height: 50vw;
    max-height: 840px;

    >* {
        display: block;
    }

    figcaption {
        display: block;
        line-height: 40px;
        padding: 10px auto;
    }

}

.image__image {
    width: 100%;
    height: 100%;
}

.image__description {
    font-size: $font-size--mobile;
    position: absolute;
    bottom: -30px;
}
