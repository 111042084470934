$font-size--mobile: 14px;
$font-size--desktop: 16px;

$h1-font-size--mobile: 36px;
$h1-font-size--desktop: 50px;
$h1-font-size--desktop-xl: 60px;

$h2-font-size--mobile: 18px;
$h2-font-size--desktop: 42px;

$h3-font-size--mobile: 32px;
$h3-font-size--desktop: 32px;

$h4-font-size--mobile: 18px;
$h4-font-size--desktop: 26px;

h1, h2, h3, h4 {
    text-align: center;
    font-weight: normal;
    margin-top: 16px;
    margin-bottom: 16px;
}

h1 {
    font-size: $h1-font-size--mobile;
    line-height: 1.05;

    @include media(sm) {
        font-size: $h1-font-size--desktop;
    }

    @include media(xl) {
        font-size: $h1-font-size--desktop-xl;
    }
}

h2 {
    font-size: $h2-font-size--mobile;
    line-height: 1.05;

    @include media(sm) {
        font-size: $h2-font-size--desktop;
    }
}

h3 {
    font-size: $h3-font-size--mobile;

    @include media(sm) {
        font-size: $h3-font-size--desktop;
    }
}

h4 {
    font-size: $h4-font-size--mobile;
    line-height: 1.3;


    @include media(sm) {
        font-size: $h4-font-size--desktop;
    }
}
