.footer {
    background-image: url("/static/images/parchment-paper-texture.jpg");
    background-size: cover;
    background-color: $color_creme;
    position: relative;

    .grid__content {
        margin: 0 auto;
    }
}

.footer__logo {
    height: 120px;

    .logo {
        position: absolute;
        top: 20px;
        left: 50%;
        width: 85px;
        height: auto;
        transform: translateX(-50%);
    }
}

.footer__payoff {
    @include BourtonBase(24px);
    text-transform: uppercase;
    text-align: center;
    color: $color_black;
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 130px;
}

.footer__wrapper {
    margin-top: 90px;
    display: flex;
    padding-bottom: 50px;

    @include media(md) {
        margin-top: 0;
        flex-direction: row;
    }
}

.footer__links {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @include media(md) {
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
    }
}

.footer__link {
    @include BourtonBase(12px);
    line-height: 1.1;
    display: block;
    padding: 6px 0;

    @include media(md) {
        margin-left: 60px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-left: auto;
        }
    }
}
