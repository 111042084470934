.product-carousel__slide {

    display: inline-block;
    position: relative;
    height: 100%;

    >img {

        position: absolute;
        bottom: 12.5%;
        left: 50%;
        transform: translateX(-50%);

        width: auto;

        // animation
        height: 70%;
        transition: all 450ms ease-in-out;
    }
}

.swiper-slide-active {
    img {

        // animation
        height: 100%;
        transform: translate(-50%, 12.5%);
    }
}
