.instagram-feed__cta {
    margin: 0 auto;
    text-align: center;
    width: 100%;

    a {
        @extend .button;
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        img {
            margin-right: 10px;
        }
    }
}

.instagram-feed__items {
    display: block;
    line-height: 0;

    @include clearfix();
}
