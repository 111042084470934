.grid__content {
    width: 100%;
    padding: 0 20px;
    margin: 30px auto;
    position: relative;

    @include media(sm) {
        width: 80%;
    }

    @include media(md) {
        max-width: 960px;
        padding: 0 42px;
        margin: 35px auto;
    }

    @include media(lg) {
        max-width: 1180px;
        padding: 0 50px;
        margin: 40px auto;
    }
}
