.rich-text {
    margin: 0 auto;

    h2, h3, h4 {
        text-align: left;
    }

    p {
        line-height: 1.5625;
    }

    ol, ul {
        padding-left: 20px;

        @include media(md) {
            padding-left: 42px;
        }

        @include media(lg) {
            padding-left: 50px;
        }

        li {
            line-height: 25px;
            @include Roboto(700, normal, $font-size--mobile);
        }
    }

    ul {
        li {
            &:before {
                content: "\2022";
                color: $color_orange;
                float: left;
                margin: 0 0 0 -1em;
                width: 1em;
            }
        }
    }
}

.richtext-image {
    width: 100%;
    height: auto;

    &.right {
        @include media(md) {
            width: auto;
            padding-left: 20px;
            float: right;
        }
    }
    &.left {
        @include media(md) {
            width: auto;
            padding-right: 20px;
            float: left;
        }
    }
}
