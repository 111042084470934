.farmer-overview {
    background-image: url("/static/images/parchment-paper-texture.jpg");
    background-size: cover;
    background-color: $color_creme;
    padding: 20px 0;
}

.farmer-overview__title {
    margin-top: 20px;
}

.farmer-overview__items {

    @include media(sm) {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;

        .farmer-block {

            flex: 0 50%;

            @include media(md) {
                flex: 1;
            }
        }
    }
}
