.custom-checkbox {

}

.custom-checkbox__input {
    display: none;
}

.custom-checkbox__label {
    @include BourtonBase(18);
    text-transform: uppercase;
    position: relative;
    display: block;
    user-select: none;
    margin-left: 70px;
    margin-bottom: 20px;
    cursor: pointer;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 13px;
        height: 13px;
        left: -50px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
        border: solid 1px $color_black;
    }
}

.custom-checkbox__input:checked + .custom-checkbox__label {
    &:before {
        border-color: $color_orange;
        background-color: $color_orange;
    }
}
