.label-header-image {
    position: relative;
    text-align: center;
    &:after {
        content: '';
        border-left: 1px dashed $color_black;
        position: absolute;
        top: 40px;
        left: 50%;
        height: 55px;
    }

    img {
        margin-top: 135px;
        margin-bottom: 40px;
        height: 100px;
    }
}
