.reviewlist {
    @include media(md) {
        padding-bottom: 75px;
        //&:before {
        //    background-image: url("/static/images/backgroundimage.svg");
        //    background-repeat: no-repeat;
        //    background-size: contain;
        //    content: '';
        //    position: absolute;
        //    top: 0;
        //    left: 0;
        //    height: 90%;
        //    z-index: 0;
        //    width: 66%;
        //}
    }

}

.swiper-container {
    width: 100%;
    height: 100%;
}

.review__image {
    padding-bottom: 50px;

    @include media(sm) {
        text-align: center;
    }
    @include media(md) {
        text-align: left;
    }

    img {
        width: 100%;

        @include media(sm) {
            width: 40%;
            margin: 0 auto;
        }
        @include media(md) {
            width: 60%;
            margin: 0;
        }
        @include media(lg) {
            width: auto;
        }
    }
}

.review__quote {
    line-height: 25px;
    padding-bottom: 50px;
    @include Roboto(700, normal, $font-size--desktop);
}

.review__person {
    position: relative;
    width: fit-content;
    @include Roboto(700, normal, 12px);

    &:after {
        content: "";
        background: $color_black;
        position: absolute;
        top: -15px;
        left: 0;
        height: 1px;
        width: calc(100% + 50px);
    }
}

.review {
    display: flex;
    flex-direction: column;

    @include media(md) {
        justify-content: space-between;
        flex-direction: row;
    }
}

.review__wrapper {
    @include media(md) {
        width: 40%;
        position: absolute;
        left: 50%;
    }
}
