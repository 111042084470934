.image-text {
    .grid__content {
        width: unset;
        max-width: unset;
    }

    .wrapper {
        @include media(md) {
            display: grid;
            grid-gap: 16px;
            align-items: center;
            grid-template-columns: repeat(12, 1fr);
        }

        &--secondary {
            background-image: url("/static/images/parchment-paper-texture.jpg");
            background-size: cover;
            background-color: $color_creme;
        }
    }
}

.image-text__image {
    grid-column: span 6;
    order: 0;

    img {
        width: 100%;
        height: auto;
    }


    .wrapper--reversed & {
        order: 1;
    }

    .wrapper--primary & {
        padding: 50px;
    }

    .wrapper--secondary & {
        height: 100%;

        img {
            height: 100%;
            object-fit: cover
        }
    }
}

.image-text__content {
    font-size: 16px;
    grid-column: span 6;
    order: 1;
    text-align: center;

    .wrapper--reversed & {
        order: 0;
    }

    .wrapper--primary & {
        padding: 30px 0;

        @include media(md) {
            padding: 50px;
        }
    }

    .wrapper--secondary & {
        padding: 30px 20px;

        @include media(md) {
            padding: 50px;
            grid-column: span 6;
        }
    }


    .block__title {
        font-size: 42px;
        margin-bottom: 16px;
    }

    .block__subtitle {
        font-size: 62px;
    }

    .rich-text p a {
        color: $color-orange;
    }

    .button {
        display: block;
        margin: 32px;

        @include media(md) {
            display: inline-block;
            margin: 32px 0 0 0;
        }
    }
}
