*,
*::after,
*::before {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {

    &.loading {
        cursor: wait;
    }

    @include Roboto(400, normal, $font-size--mobile);
    color: $color_black;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include media(md) {
        font-size: $font-size--desktop;
    }
}

.main--no-header {
    min-height: calc(100vh - 70px);
    padding-top: 50px;

    @include media(md) {
        padding-top: 100px;
    }
}

.text {
    line-height: 24px;

    @include media(md) {
        line-height: 32px;
    }
}

.text--centered {
    text-align: center;
}

html.sr {
    .header__content, .usp__item, .feed__wrapper {
        visibility: hidden;
    }
}

// hide the recaptcha badge, using the custom message option.
.grecaptcha-badge {
    display: none;
    visibility: hidden;
    opacity: 0;
}
