.hexagon {
    //@include hexagon(32px);
    @include BourtonBase(20px);

    background-image: url("/static/images/hexagon.svg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
    height: 30px;
}

.hexagon-light {
    background-image: url("/static/images/hexagon-light.svg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
}

.hexagon__text {
    position: absolute;
    top: 23px;
    left: 0;
    z-index: 1;
    font-size: 17px;
    color: $color_white;
    width: 100%;
    text-align: center;

    @include media(md) {
        top: 7px;
    }
}
