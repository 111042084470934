.farmer-block {
    text-align: center;
    width: 100%;
}

.farmer-block__image {
    img {
        max-width: 180px;
    }

    @include media(sm) {
        img {
            max-width: 100px;
        }
    }
    @include media(md) {
        min-height: 195px;
        img {
            max-width: 150px;
        }
    }
    @include media(lg) {
        min-height: 320px;
        img {
            max-width: 250px;
        }
    }
}

.farmer-block__title {
    @include BourtonBase(20px);
    color: $color_black;
    margin-top: 10px;

    @include media(sm) {
        @include BourtonBase(28px);
    }
}

.farmer-block__location {
    @include BourtonBase(16px);
    margin-top: 20px;

    @include media(sm) {
        @include BourtonBase(18px);
    }
}
