.product-overview__slide {

    position: relative;
    vertical-align: middle;

    display: block;
    float: left;

    width: 100%;
    height: 600px;

    @include clearfix();
    margin-bottom: 60px;

    @include media(sm) {
        margin-bottom: 40px;
    }

    @include media(md) {

        display: inline-block;
        width: 50%;
        height: 420px;
        max-height: 400px;
    }

    @include media(lg) {

        height: 460px;
        max-height: 460px;
    }

    a {

        .hexagon {

            position: relative;
            vertical-align: middle;

            top: 220px;

            width: 100%;
            height: 400px;

            @include media(sm) {
                width: 85%;
                left: 7.5%;
                height: 365px;
            }

            @include media(md) {

                display: table-cell;
                float: right;

                top: auto;
                left: auto;

                height: 90%;
                width: 90%;
            }
        }

        .slide__description {

            position: absolute;
            text-align: center;

            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 50%;
            height: 70%;

            @include media(md) {

                top: 50%;
                height: 90%;
            }

            > .wrapper {

                position: relative;
                display: block;

                height: 28%;

                @include media(md) {
                    height: 33%;
                }

                width: 100%;

                text-align: center;

                > * {

                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    width: 100%;

                    vertical-align: middle;
                }
            }

            .farm-logo {

                display: inline;
                box-sizing: border-box;
                width: auto;
                height: 60px;
            }

            h3 {
                width: 100%;
                margin: auto 0;
                color: $color_white;

                font-size: $h3-font-size--desktop;
            }

            .farm-location {
                margin-top: 20px;
                width: 100%;

                @include Roboto(400, normal, $font-size--mobile);

                font-size: $font-size--mobile;

                @include media(md) {
                    margin-top: unset;
                }

                @include media(lg) {
                    font-size: 16px;
                }

                img {

                    width: 18px;
                    margin-bottom: 2px;

                    @include media(md) {
                        width: 24px;
                        margin-bottom: 4px;
                    }
                }

                p {
                    margin: 0;
                }
            }
        }


        & > img {

            position: absolute;
            width: auto;
            z-index: 1;

            left: 50%;
            transform: translateX(-50%);
            height: 280px;

            @include media(md) {
                top: 0;
                left: -1vw;

                transform: none;

                height: 40vw;
                max-height: 400px;
                max-width: 175px;
            }

            @include media(lg) {

                height: 45vw;
                max-height: 420px;
            }

        }
    }
}
