.columns-list {

    --column-count: 2;

    background-image: url("/static/images/parchment-paper-texture.jpg");
    background-size: cover;
    background-color: $color_creme;
    padding: 50px 0;

    &__title {
        font-size: 62px;
    }

    &__content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }


     &-item {

            flex: 0 0 100%;

            &__title {
                @include BourtonBase(32px);
                color: $color_orange;
                text-align: left;
                margin-bottom: 12px;
            }

            &__text {
                @include richtext();
            }
        }

        @include media(md) {
           > &-item {
               flex: 0 0 calc(100% / 3);
               padding: 10px;
            }
        }

}
