.story-container {
    overflow: hidden;
    padding: 40px 0;

    .wrapper {
        height: auto;
        transform: none !important;
        overflow: hidden;
    }
}


.story-container__title {
    color: $color-orange;
}

.story-container__list {
    height: 100%;
}

.story-container__slide {
    display: inline-block;
    position: relative;
    height: auto !important;

    &.swiper-slide[style] {
        margin-top: 0 !important;
        margin-bottom: 75px;
    }

    &__top {
        position: relative;
        overflow: hidden;

        > img {
            position: relative;
            width: 100%;
            height: auto !important;
            max-height: unset;
            transform: none !important;
            overflow: hidden;
        }
    }

    &__bottom {
        position: relative;
        top: 0;
        width: 100%;
        height: auto !important;

        &__description {
            @include BourtonBase(22px);
            text-align: left;
            margin-top: 16px;
            margin-bottom: 24px;
        }

        &__title {
            @include Roboto(400, normal, 16px);
            text-align: left;
            line-height: 25px;
            margin: 0;
        }
    }
}

.story-container__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 30px;

    .swiper-pagination {
        white-space: nowrap;

        .swiper-pagination-bullet {
            height: 16px;
            width: 16px;
            background-color: $color-creme;
            margin: 0 4px;
            opacity: 1;

            &:only-child {
                display: none;
            }
        }

        .swiper-pagination-bullet-active {
            background-color: $color-orange;
        }
    }
}

.story-container__controls {
    //position: absolute;
    background-image: none;
    display: block;
    top: 14px;
    transition: opacity 600ms;

    &.swiper-button-prev {
        transform: translateX(-64px);
    }

    &.swiper-button-next {
        transform: translateX(64px);
    }

    &.swiper-button-disabled {
        opacity: 0;
    }
}
