.feed--cow-detail {
    margin-top: 20px;
}


.feed__content {
    display: flex;
    flex-direction: column;

    @include media(md) {
        flex-direction: row;
        justify-content: space-evenly;
    }
}

.feed__time {
    position: relative;
    min-width: 70px;
    margin: 0 10px 0 0;
}

.feed__bubble {
    width: 70px;
    background-color: $color_brown;
    color: $color_white;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    margin-right: 10px;
    position: absolute;
    z-index: 1;
    top: -5px;

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent $color_brown;
    }
}

.feed__cow-text {
    line-height: 23px;
    margin: -5px 0 0;

    padding-left: 20px;
    @include Roboto(700, normal, $font-size--desktop);

    @include media(md) {
        font-size: $h4-font-size--mobile;
    }
}

.feed__cow-traffic {
    display: flex;
    padding: 20px 0;

    &:last-of-type {
        .hexagon__border {
            display: none;
        }
    }
}

.feed__title {
    text-align: left;
    @include BourtonBase(26px);
    color: $color_orange;

    @include media(md) {
        @include BourtonBase(32px);
    }
}

.feed__hexagon {
    margin-top: -8px;
    width: 21px;

    .hexagon {
        width: 28px;
        position: relative;
        background-position: 0 0;

        .hexagon__text {
            top: 8px;
            width: 100%;
        }
    }

    // todo: fix this border
    //.hexagon__border {
    //    &:after {
    //        width: 60px;
    //        content: '';
    //        border-bottom: 1px solid #000000;
    //        position: absolute;
    //        top: 16px;
    //        left: 50%;
    //
    //        @include media(md) {
    //            width: 100px;
    //        }
    //
    //        @include media(lg) {
    //            width: 75px;
    //        }
    //    }
    //}
}

.feed__wrapper {
    position: relative;

    @include media(md) {
        width: 40%;

        .feed--cow-detail & {
            width: 100%;
        }
    }
}

.feed__family {
    line-height: 23px;
    width: 50%;

    @include media(md) {
        width: auto;
    }
}

.feed__family-name {
    @include Roboto(700, normal, 18px);
    display: block;

    @include media(md) {
        display: inline-block;
    }
}

.feed__results {
    display: flex;

    @include media(md) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.feed__bottles {
    @include media(md) {
        margin: 0;
        min-width: 65px;
    }
}

.feed__bottle-icon {
    padding-right: 5px;
}

.feed__loader,
.feed__loader:after {
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.feed__loader {
    transition: opacity 200ms;
    position: absolute;
    top: 20px;
    right: 0;

    opacity: 0;

    margin: 10px;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 5px solid rgba(208, 149, 86, 0.2);
    border-right: 5px solid rgba(208, 149, 86, 0.2);
    border-bottom: 5px solid rgba(208, 149, 86, 0.2);
    border-left: 5px solid #d09556;
    transform: translateZ(0);
    animation: feed__loader 1.1s infinite linear;

    .feed--cow-detail & {
        top: -30px;
        right: 0;
    }
}

.feed__loader--active {
    opacity: 1;
}

@keyframes feed__loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
