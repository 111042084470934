.mktoForm {
    display: grid;
    justify-content: center;
    width: 100% !important;
    margin: 0 auto;
    padding: 0 !important;

    @include media(md) {
        width: 600px !important;
        margin: 0 auto !important;
    }

    label {
        font-family: "Bourton Base", serif;
        font-size: 18px !important;
        text-transform: uppercase !important;
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
    }

    input {
        &.mktoTextField,
        &.mktoEmailField,
        &.mktoTelField
        {
            background-color: #F0F0E6;
            height: 40px !important;
            padding: 10px !important;
            font-size: 16px !important;
        }
    }

    a {
        color: #f26752;
    }

    select {
        background-color: #F0F0E6;
        height: 40px !important;
        border: none !important;
        padding: 10px !important;
        font-size: 16px !important;
    }

    legend {
        display: none;
    }
}

.mktoFormRow {
    width: 100%;
}

.mktoFormCol {
    width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
}

.mktoFieldWrap {
    display: flex;
    flex-direction: column;
}

.mktoGutter {
    display: none;
}
.mktoOffset {
    display: none;
}

.mktoLabel {
    width: 100% !important;
    font-weight: normal !important;
    padding: 10px 0 !important;
}

.mktoField {
    width: 100% !important;
}

.mktoLogicalField
{
    width: 100% !important;
    display: flex;
    flex-direction: row;

    input {
        width: auto !important;
    }
    label {
        width: auto !important;
        margin: 0.2em 0 0 1em !important;
        font-size: 16px !important;
    }
}

.mktoHtmlText {
    width: 100% !important;
}

.mktoButtonRow {
    .mktoButtonWrap.mktoSimple{
        margin: 0 !important;

        .mktoButton {
            font-weight: 700 !important;
            font-family: "Roboto", sans-serif !important;
            font-style: normal !important;
            font-size: 16px !important;
            background-color: #FF684D !important;
            background-image: none !important;
            padding: 10px 20px !important;
            color: #FFFFFF !important;
            display: block !important;
            margin: 30px 0 !important;
            border-radius: 5px !important;
            border: none !important;
        }
    }
}
