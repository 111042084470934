// Project colors
$color_creme: #F0F0E6;
$color_orange: #FF684D;
$color_blue: #1D6CCE;
$color_brown: #623131;

// Basic colors
$color_black: #000000;
$color_white: #FFFFFF;

